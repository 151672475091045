// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapitola-01-js": () => import("./../src/pages/kapitola_01.js" /* webpackChunkName: "component---src-pages-kapitola-01-js" */),
  "component---src-pages-kapitola-02-js": () => import("./../src/pages/kapitola_02.js" /* webpackChunkName: "component---src-pages-kapitola-02-js" */),
  "component---src-pages-kapitola-03-js": () => import("./../src/pages/kapitola_03.js" /* webpackChunkName: "component---src-pages-kapitola-03-js" */),
  "component---src-pages-kapitola-04-js": () => import("./../src/pages/kapitola_04.js" /* webpackChunkName: "component---src-pages-kapitola-04-js" */),
  "component---src-pages-kapitola-05-js": () => import("./../src/pages/kapitola_05.js" /* webpackChunkName: "component---src-pages-kapitola-05-js" */),
  "component---src-pages-kapitola-06-js": () => import("./../src/pages/kapitola_06.js" /* webpackChunkName: "component---src-pages-kapitola-06-js" */),
  "component---src-pages-kapitola-07-js": () => import("./../src/pages/kapitola_07.js" /* webpackChunkName: "component---src-pages-kapitola-07-js" */),
  "component---src-pages-kapitola-08-js": () => import("./../src/pages/kapitola_08.js" /* webpackChunkName: "component---src-pages-kapitola-08-js" */),
  "component---src-pages-kapitola-09-js": () => import("./../src/pages/kapitola_09.js" /* webpackChunkName: "component---src-pages-kapitola-09-js" */),
  "component---src-pages-kapitola-10-js": () => import("./../src/pages/kapitola_10.js" /* webpackChunkName: "component---src-pages-kapitola-10-js" */),
  "component---src-pages-kapitola-11-js": () => import("./../src/pages/kapitola_11.js" /* webpackChunkName: "component---src-pages-kapitola-11-js" */),
  "component---src-pages-kapitola-12-js": () => import("./../src/pages/kapitola_12.js" /* webpackChunkName: "component---src-pages-kapitola-12-js" */),
  "component---src-pages-kapitola-13-js": () => import("./../src/pages/kapitola_13.js" /* webpackChunkName: "component---src-pages-kapitola-13-js" */),
  "component---src-pages-kapitola-14-js": () => import("./../src/pages/kapitola_14.js" /* webpackChunkName: "component---src-pages-kapitola-14-js" */),
  "component---src-pages-kapitola-15-js": () => import("./../src/pages/kapitola_15.js" /* webpackChunkName: "component---src-pages-kapitola-15-js" */),
  "component---src-pages-kapitola-16-js": () => import("./../src/pages/kapitola_16.js" /* webpackChunkName: "component---src-pages-kapitola-16-js" */),
  "component---src-pages-kapitola-17-js": () => import("./../src/pages/kapitola_17.js" /* webpackChunkName: "component---src-pages-kapitola-17-js" */),
  "component---src-pages-kapitola-18-js": () => import("./../src/pages/kapitola_18.js" /* webpackChunkName: "component---src-pages-kapitola-18-js" */),
  "component---src-pages-predhovor-js": () => import("./../src/pages/predhovor.js" /* webpackChunkName: "component---src-pages-predhovor-js" */),
  "component---src-pages-uvod-js": () => import("./../src/pages/uvod.js" /* webpackChunkName: "component---src-pages-uvod-js" */),
  "component---src-pages-zaver-js": () => import("./../src/pages/zaver.js" /* webpackChunkName: "component---src-pages-zaver-js" */)
}

